<template>
  <div>
    <appheader index="3"></appheader>
    <div class="list">
      <div class="list_title">修改密码</div>
      <div class="modify">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="旧原密码" prop="oldPassword">
            <el-input v-model="ruleForm.oldPassword" autocomplete='off' type='text' :readonly='ruleForm.readonly'
              @focus='handlerIptClick' :maxlength="16" :placeholder="placeholdffer">
            </el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input v-model="ruleForm.password" :type="passw" @blur="onBlur('新密码')" :maxlength="16"
              placeholder="请输入新密码">
              <!-- input中加图标必须要有slot="suffix"属性，不然无法显示图标 -->
              <i slot="suffix" :class="icon" @click="showPass('新密码')"></i>
            </el-input>
          </el-form-item>
          <el-form-item label="重复新密码" prop="repeatPassword">
            <el-input v-model="ruleForm.repeatPassword" :type="passwTwo" @blur="onBlur('重复新密码')" :maxlength="16"
              placeholder="重复输入新密码">
              <!-- input中加图标必须要有slot="suffix"属性，不然无法显示图标 -->
              <i slot="suffix" :class="iconTwo" @click="showPass('重复新密码')"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="prompt">密码必须包含大小写字母、数字、特殊符号(@#%$&^*()+_/,!~)中的两种，长度为8到16位</div>
          </el-form-item>
          <el-form-item class="btn">
            <el-button @click="resetForm">确认</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import Appheader from "../moudel/Appheader.vue";
import bottom from "../bady_bottom/bottom";
import { oldPasswordFive, oldPasswordTwo, oldPasswordThree, oldPasswordFour } from '@/utils/index'
export default {
  name: "Changepassword",
  data() {
    var oldPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('旧原密码不能为空！'));
      }
    };
    var password = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('新密码不能为空！'));
      } else if (value.length < 8) {
        callback(new Error('密码长度在 8 到 16 个字符'));
      } else if (!oldPasswordFive(value) && !oldPasswordTwo(value) && !oldPasswordThree(value) && !oldPasswordFour(value)) {
        callback(new Error('请输入有效的密码格式'));
      } else if (this.ruleForm.repeatPassword && (this.ruleForm.password != this.ruleForm.repeatPassword)) {
        callback(new Error('两次密码不一致！'));
      }
    };
    var repeatPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入重复新密码！'));
      } else if (value.length < 8) {
        callback(new Error('密码长度在 8 到 16 个字符！'));
      } else if (!oldPasswordFive(value) && !oldPasswordTwo(value) && !oldPasswordThree(value) && !oldPasswordFour(value)) {
        callback(new Error('请输入有效的密码格式！'));
      } else if (this.ruleForm.password && (this.ruleForm.password != this.ruleForm.repeatPassword)) {
        callback(new Error('两次密码不一致！'));
      }
    };
    return {
      ruleForm: {
        password: '',
        oldPassword: '',
        repeatPassword: '',
        readonly: true,
      },
      icon: "el-input__icon el-icon-view",
      passwTwo: "password",
      iconTwo: "el-input__icon el-icon-view",
      passw: "password",
      placeholdffer: "云平台初始密码88888888",
      rules: {
        oldPassword: [
          { validator: oldPassword, trigger: 'blur' }
          // { required: true, message: '请输入旧原密码', trigger: 'blur' },
          // { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' }
        ],
        password: [
          { validator: password, trigger: 'blur' }
        ],
        repeatPassword: [
          { validator: repeatPassword, trigger: 'blur' }
        ],
      }
    };
  },
  components: {
    Appheader,
    bottom,
  },
  mounted() {

  },
  methods: {
    // 解决自动填充问题
    handlerIptClick() {
      this.ruleForm.readonly = false
    },
    //密码的隐藏和显示
    showPass(text) {
      if (text == "新密码") {
        //点击图标是密码隐藏或显示
        if (this.passw == "text") {
          this.passw = "password";
          //更换图标
          this.icon = "el-input__icon el-icon-view";
        } else {
          this.passw = "text";
          this.icon = "el-icon-hk-yincang";
        }
      } else {
        //点击图标是密码隐藏或显示
        if (this.passwTwo == "text") {
          this.passwTwo = "password";
          //更换图标
          this.iconTwo = "el-input__icon el-icon-view";
        } else {
          this.passwTwo = "text";
          this.iconTwo = "el-icon-hk-yincang";
        }
      }
    },
    //密码失焦事件
    onBlur(text) {
      if (text == "新密码") {
        this.passw = "password";
        this.icon = "el-input__icon el-icon-view";
      } else {
        this.passwTwo = "password";
        this.iconTwo = "el-input__icon el-icon-view";
      }
    },
    resetForm() {
      var url = '/system/user/updatePassword';
      this
        .$axios({
          url: url,
          method: "post",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            password: this.ruleForm.password,
            oldPassword: this.ruleForm.oldPassword,
            repeatPassword: this.ruleForm.repeatPassword,
            userCode: this.$route.query.code ? this.$route.query.code : JSON.parse(localStorage.getItem("userData")).userCode
          }),
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1000,
              onClose: () => { this.$router.push('/login') }
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: 'warning',
              duration: 1000,
            });

          }
        });
    }
  }
}
</script>
<style scoped lang="scss">
.list {
  width: 1500px;
  margin: 0 auto;
  min-height: 550px;

  .btn {
    text-align: center;

    .el-button {
      border-color: #ec9368 !important;
      color: #fff;
      border-radius: none !important;
      background: #ec9368;
      width: 200px;
    }
  }
}

.list_title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin: 28px 0;
}

.modify {
  width: 500px;
  margin: 100px auto;

  .el-form-item {
    height: 65px;
  }
}

.el-icon-hk-yincang {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 40px;
}</style>
